<template>
  <div>
    <van-cell title="日期" :value="date.val" @click="date.show = true" is-link />
    <van-calendar v-model="date.show" type="range" @confirm="dateSel" :allow-same-day="true" :min-date="date.minDate" />

    <van-empty description="暂无数据" v-if="list.length == 0" />
    <template v-else>
      <div class="datas">
        <van-row class="th-row" style="display:flex;">
          <van-col span="12">功能室</van-col>
          <van-col span="6" align="center">使用次数</van-col>
          <van-col span="6" align="center">总次数</van-col>
        </van-row>
        <van-row v-for="item in list" :key="item.Func_Room_Id" class="td-row" style="display:flex;">
          <van-col span="12">{{ item.Func_Room_Name }}</van-col>
          <van-col span="6" align="center">{{ item.RoomUseCount }}</van-col>
          <van-col span="6" align="center">{{ item.AllRoomUseCount }}</van-col>
        </van-row>
      </div>
    </template>
    <div class="chart" id="chart" style="width: 300px;height: 300px"></div>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "index",
  data() {
    return {
      date: {
        val: '',
        show: false,
        minDate: moment('2021-07-01').toDate()
      },
      grid: { loading: false },
      list: []
    }
  },
  created() {

  },
  methods: {
    dateSel(d) {
      this.DtS = moment(d[0]).format('YYYY-MM-DD');
      this.DtE = moment(d[1]).format('YYYY-MM-DD')
      this.date.show = false;
      this.date.val = this.DtS + ' 至 ' + this.DtE;
      this.getResult()
    },
    getResult() {
      let self = this
      self.grid.loading = true
      let { DtS, DtE } = this
      this.whale.remote.getResult({
        url: "/api/Mobile/FUNC/FuncRoomUse/GetRoomUseData",
        data: { DtS, DtE },
        finally() { self.grid.loading = false; },
        completed: function (its) {
          if (its.DATA) {
            self.list = its.DATA
            self.$nextTick(() => {
              self.getChart(its.DATA)
            })
          }
        }
      })
    },
    getChart(data) {
      var chartDom = document.getElementById('chart');
      var myChart = this.$echarts.init(chartDom);
      var option;
      let arr = data.reduce((prev, cur) => {
        prev.push({
          value: cur.RoomUseCount,
          name: cur.Func_Room_Name
        })
        return prev
      }, [])
      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '40%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: arr
          }
        ]
      };

      option && myChart.setOption(option);
    },
  }
}
</script>

<style scoped>
/deep/ .van-cell__title {
  flex: 0 0 auto;
}

/deep/ .van-cell__value {
  flex: 1;
}

.datas {
  font-size: 13px;
  margin: 12px;
  border: 1px solid #ddd;
  background: #fff;
}

.th-row {
  height: 30px;
  line-height: 30px;
  padding: 0px 12px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.td-row {
  height: 30px;
  line-height: 30px;
  padding: 0px 12px;
}

.td-row+.td-row {
  border-top: 1px solid #ddd;
}

.van-col+.van-col {
  border-left: 1px solid #ddd;
}
</style>